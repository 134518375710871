<template>
  <section class="atendimentoWrap">
    <div class="logo">
      <img src="@/assets/img/logo_rede.svg"/>
    </div>
  </section>
</template>

<script>
export default {
  data () {
    return {
      chatbot: null,
      call: null
    }
  },
  mounted () {
    // const script = document.createElement('script')
    // script.src = "https://api.rededecisao.com/chatbot/embed.js"
    // script.async = true

    // script.onload = () => {
    //   if (typeof embedChatbot === 'function') {
    //     window.embedChatbot({fixed: true, width: '800px'})
    //   } else {
    //     console.error("embedChatbot function is not defined in the loaded script.")
    //   }
    // }

    // document.head.appendChild(script)

    const script = document.createElement('script')
    script.src = "https://api.rededecisao.com/chatbot/embed.js"
    script.async = true

    script.onload = () => {
      try {
        if (typeof window.embedChatbot === 'function') {
          window.embedChatbot({ fixed: true, width: '800px' })
        } else {
          console.error("embedChatbot function is not defined in the loaded script.")
        }
      } catch (error) {
        console.error("Error while running embedChatbot:", error)
      }
    };

    script.onerror = () => {
      console.error("Failed to load the embed.js script. Check the server response.")
    };

    document.head.appendChild(script)
  }
}
</script>

<style lang="scss" scoped>
  .atendimentoWrap {
    width: 100vw; height: 100vh;
    background-color: $color-bg;
    background-image: url('@/assets/img/bg_alunos.jpg');
    background-position: center;
    background-repeat: no-repeat;

    .logo {
      position: absolute;
      left: 0; top: 0;
      padding: $mg_mid;
      background-color: $color-secondary;
      border-bottom-right-radius: 3em;

      img {
        width: 200px
      }
      @media screen and (max-width: 440px) {
        padding: $hmg_mid;
        border-bottom-right-radius: 2em;
        img {
          width: 110px
        }
      }
    }
  }
</style>