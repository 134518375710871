<script setup>
  import AcademicoService from '@/services/academico.service.js'
  import { createSelectOptions } from '@/services/utils.service.js'
  import Modal from "@/components/utilities/Modal"
  import UserService from '@/services/user.service.js'
  import MatriculaService from '@/services/matricula.service.js'
  import MiniSpinner from '@/components/utilities/MiniSpinner.vue'
</script>

<template>
    <section class="frame">
      <div class="fancy-list">
        <div class="fancy-list-header"></div>
        <ul>
          <li v-for="(matricula, i) in matriculas" :key="matricula" :style="{'z-index': 1000 - i}">
            <card :boxed="false" overflow="visible" :listed="false" hover>
              <div class="fancy-list-item">
                <header>
                  <div class="picWrap">
                    <span v-if="matricula.urlFoto" :style="{ backgroundImage: `url('${matricula.urlFoto}')` }"></span>
                    <span v-else></span>
                  </div>
                  <span>
                    <label>{{ matricula.idMatricula }} - {{ matricula.nomeTurma }}</label><br />
                    <h4>{{ matricula.nomeAluno }}</h4>
                  </span>
                  <span>
                    <fa :icon="['far', 'arrow-right-arrow-left']" />
                  </span>
                  <span>
                    <div>
                      <select-control
                        :options="options.series"
                        v-model="matricula.idSerieDestino"
                        placeholder="Série"
                        @change="getTurmas(matricula.idSerie, i)"
                        :disabled="!allowFullRemanejamento || matricula.done"
                      />
                    </div>
                  </span>
                  <div>
                    <div>
                      <select-control
                        :options="matricula.turmas"
                        v-model="matricula.idTurmaDestino"
                        placeholder="Turma"
                        @change="toggleReplication(i)"
                        :disabled="matricula.done"
                      />
                    </div>
                    <div v-if="i == 0 && replicar" class="replicar">
                      <card :listed="false">
                        <div class="replicarController">
                          <fa :icon="['far', 'arrows-repeat']" />
                          <a>Deseja replicar para os outros alunos? </a>
                          <nav>
                            <a href="#" class="btn btn-primary" @click.prevent="validateReplication()">Sim</a>
                            <a href="#" class="btn btn-secondary" @click.prevent="toggleReplication(i, true, true)">Não</a>
                          </nav>
                        </div>
                      </card>
                    </div>
                  </div>
                  <span>
                    <mini-spinner v-if="matricula.processing"/>
                    <fa :icon="['far','check']" v-else-if="matricula.done" class="done"/>
                    <label class="check-control nolabel" v-else>
                      <input
                        type="checkbox"
                        class="check"
                        v-model="matricula.selected"
                      />
                    </label>
                  </span>
                </header>
              </div>
            </card>
          </li>
        </ul>
      </div>
      <footer :class="{ hom: hom }">
        <a href="#" :class="['btn btn-primary',{ disabled: processing }]" @click.prevent="orchestrate()">Remanejar</a>
      </footer>
      <modal ref="modal" wrapped> 
        <div class="vagaConfirm">
          <div>
            <fa :icon="['fal','triangle-exclamation']" class="icon" />
            <h5>Não existem vagas disponíveis o suficiente para remanejar todos os alunos selecionados</h5>
            <ul>
              <li>
                <span>{{ vagas.disponiveis }}</span>
                <p>Vagas disponíveis</p>
              </li>
              <li>
                <span>{{ vagas.selecionadas }}</span>
                <p>Alunos selecionados</p>
              </li>
              <li>
                <span>{{ vagas.faltando }}</span>
                <p>Vagas faltando</p>
              </li>
            </ul>
          </div>
          <footer>
            <a href="#" class="btn btn-secondary" @click.prevent="toggleReplication(null, true)">Cancelar</a>
            <a href="#" class="btn btn-primary" @click.prevent="replica">Selecionar disponíveis</a>
          </footer>
        </div>
      </modal>
    </section>
  </template>
  
  <script>
  export default {
    components: {
      Modal, MiniSpinner
    },
    props: {
      scope: {
        type: Array,
      },
      filter: {
        type: Object,
      },
      options: {
        type: Object,
      }
    },
    watch: {
      scope(value) {
        if (value) {
          this.matriculas = value
          this.setMatriculas()
          this.definirTurmas()
          this.createToggle()
        }
      },
      filter(value) {
        if (value) {
          this.filtro = Object.assign({}, value)
        }
      },
      options(value) {
        if (value) {
          this.opcoes = Object.assign({}, value)
          this.definirTurmas()
        }
      },
    },
    data() {
      return {
        matriculas: null,
        filtro: null,
        opcoes: null,
        replicar: false,
        all: false,
        hom: false,
        allowFullRemanejamento: false,
        vagas: {
          selecionadas: 0,
          disponiveis: 0,
          faltando: 0
        },
        processing: false,
        runner: []
      }
    },
    mounted() {
      this.hom = process.env.VUE_APP_ENV != 'PROD'

      this.allowFullRemanejamento = UserService.filterPermissions('remanejaFull')

      this.matriculas = this.scope
      this.filtro = Object.assign({}, this.filter)
      this.opcoes = Object.assign({}, this.options)
  
      this.definirTurmas()
      this.setMatriculas()
      this.createToggle()
    },
    methods: {
      setMatriculas () {
        this.matriculas.map( k => {
          k['idTurmaDestino'] = k.idTurma
          k['idSerieDestino'] = k.idSerie
          k['processing'] = false
          k['done'] = false
          k['fail'] = false
        })
      },
      definirTurmas(i) {
        if (i || i == 0) {
          this.matriculas[i].turmas = Object.assign([], this.opcoes.turmas)
        } else {
          this.matriculas.map( k => {
            k['turmas'] = Object.assign([], this.opcoes.turmas)
          })
        }
      },
      getTurmas(idSerie, i) {
        this.loadingOpts = true;
        AcademicoService.getTurmas(idSerie).then(
          (response) => {
            if (response.data.length > 0) {
              this.opcoes.turmas = createSelectOptions(response.data, 'idTurma', 'descricao');
              this.definirTurmas(i)
            } else {
              window.toaster.emit('open', {
                style: 'error',
                message: 'Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.',
                floater: true
              })
            }
            this.loadingOpts = false;
          },
          (error) => {
            console.error(error);
            this.loadingOpts = false;
            window.toaster.emit('open', {
              style: 'error',
              message:'Ops. Algo aconteceu. Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.',
              floater: true
            })
          }
        );
      },
      toggleReplication(i, fechar, select) {
        if (fechar) {
          this.replicar = false

          this.vagas = {
            selecionadas: 0,
            disponiveis: 0,
            faltando: 0
          }
          this.$refs.modal.close()
          
          if ( select ) this.matriculas[i].selected = true
        } else if (i == 0) {
          this.replicar = true
        } else {
          this.matriculas[i].selected = true
        }
      },
      validateReplication () {
        const turmas = Object.assign([],this.matriculas[0].turmas)
        console.log('turmas', turmas)
        const limit = turmas.filter( a => a.idTurma == this.matriculas[0].idTurmaDestino)[0].vagas
        console.log('limit',limit)

        const seleciondas = this.matriculas.length
        console.log('seleciondas',seleciondas)
        this.vagas = {
          selecionadas: seleciondas,
          disponiveis: limit,
          faltando: seleciondas - limit
        }

        console.log('this.vagas',this.vagas)

        if ( seleciondas > limit ) {
          const options = {
            size: "small", //small, mid, normal, large, full
            backdrop: true,
            backdropColor: "transparent",  //se o backdrop for false, não precisa
            backdropClose: false,
            padding: 0,
            // onClose: this.toggleReplication(null, true)
          }

          console.log("toggleTransfer:", options)
          this.$refs.modal.open(options)
        } else {
          this.replica()
        }
      },
      replica() {
        this.matriculas.map((k, i) => {  
          console.log('i', i)
          
          this.matriculas[0].selected = true
          
          if ( i > 0 && i < this.vagas.disponiveis ) {
            this.getTurmas(this.matriculas[0].idSerieDestino, i)
            k.idSerieDestino = this.matriculas[0].idSerieDestino
            k.idTurmaDestino = this.matriculas[0].idTurmaDestino
            k.selected = true
            this.replicar = false
          }
        })

        this.toggleReplication(null, true)
        
      },
      createToggle() {
        this.matriculas.map((k) => {
          k.selected = false
        })
      },
      toggleAll() {
      //
      },
      orchestrate () {
        const selected = this.matriculas.filter( a => a.selected )
        const hasSelected = selected.length > 0
        if ( hasSelected ) this.processing = true
        
        this.matriculas.map( ( k, i ) => {
          // console.log("k:", k)

          if ( k.selected ) {
            const turmaDestino = this.options.turmas.filter( a => a.idTurma == k.idTurmaDestino)[0]
            console.log("turmaDestino:", turmaDestino)

            const data = {
              matCodigo: k.matCodigo,
              idTurmaDestino: k.idTurmaDestino,
              turCodigoDestino: turmaDestino.TURCodigo,
              turmaDestino: turmaDestino.descricao,
              idMatricula: k.idMatricula,
              serieAtual: k.idSerie,
              serieDestino: k.idSerieDestino
            }
            this.runner.push({data, i})
          }
        })
        // console.log("this.runner:", this.runner)
        this.remanejar(this.runner[0])
      },
      remanejar ( opt ) {
        const i = opt.i
        const data = opt.data

        this.matriculas[i].processing = true
        console.log("opt", opt)

        MatriculaService.remanejar(data).then(
          response => {
            console.log("response", response.data)

            this.matriculas[i].processing = false
            this.matriculas[i].done = true
            this.matriculas[i].selected = false

            this.runNext(i)
          },
          error => {
            const defaultMessage = error.response.data.error
            const fallback = error.response.data.message
            var msg = defaultMessage ? defaultMessage : fallback
                msg = msg ? msg : 'Não foi possível fazer o remanejamento. No momento não temos mais informações a respeito do erro.'

            console.log("error", error.response.data)
            this.matriculas[i].processing = false
            this.matriculas[i].fail = true

            window.toaster.emit('open', {
              style: 'error',
              message: msg,
              floater: true
            })
          }
        )
      },
      runNext ( i ) {
        if ( i < this.runner.length ) {
          i++
          console.log("run:", i)
          console.log(this.runner[i])

          this.remanejar(this.runner[i])
        } else {
          this.processing = false
        }

      }
    }
  }
  </script>
  


<style lang="scss" scoped>
.fancy-list {
  padding: $hmg_mid $hmg_mid $mg_large $hmg_mid;

  .fancy-list-header {
    padding: 0 $hmg; margin-bottom: $mg_mini;
    display: grid;
    width: 100%;
    align-content: stretch; align-items: center;
    grid-template-columns: 60px 60px 1.5fr minmax(300px, 3fr) 2fr 40px 40px;
    gap: $hmg_small;
    font-size: $font-size-small;
    font-weight: bold;
  }
  
  .toggleIconWrap {
    font-size: 18px;
    color: $color-primary;
    text-align: right;
    line-height: 1;
  }

  .actions {
    font-size: 14px;
    &:hover { 
      color: $color-primary
    }
  }

  ul {
    padding: 0; margin: 0;
    
    li {
      padding: 0;
      list-style: none;
      margin: 0 0 $mg_mini 0;
      position: relative;

      .thumb {
        display: block; width: 34px; height: 34px;
        border-radius: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
      }

      .fancy-list-item {
        >header {
          display: grid;
          width: 100%;
          align-content: stretch; align-items: center;
          grid-template-columns: 60px 1fr 40px 0.5fr 0.5fr 60px;
          gap: $hmg_mid;

          >span {
            padding: $hmg_mid 0;

            &:last-child {
              border-left: $border-component;
              height: 100%;
              display: flex;
              align-items: center; justify-content: center;
            }

            label, h4 {
              margin: 0; padding: 0;
            }
          } 

          >div {
            position: relative
          }
        }
      }
      
      .toggle {
        display: inline-block; width: 18px; height: 18px; border-radius: 100%;
        border: 1px solid $color-fade; margin-right: $hmg_small;
        transform-origin: center; @extend %transition_4e;
        position: relative; pointer-events: none;

        &:after {
          content: ""; display: block;
          border-radius: 100%; background-color: $color-primary;
          width: 10px; height: 10px; transform-origin: top center;
          transform: scale(0) translateY(-50%);
          position: absolute; top: 50%;
          left: 0; right: 0; margin: auto;
          @extend %transition_4e;
        }

        &.active {
          background-color: $color-bg;
          pointer-events: all;
          &:after {
            transform: scale(1) translateY(-50%);
          }
          
        }
      }
    }
  }

  .picWrap {
    padding: 0 $hmg_mid;
    position: relative;
    flex-shrink: 0;

    span {
      display: block;
      width: 40px; height: 40px;
      border-radius: 100%;
      background-size: cover; background-position: center;
      background-repeat: no-repeat;
      background-color: $color-bg;
      image-rendering: -webkit-high-quality;
      image-rendering: -webkit-optimize-contrast;
      @extend %transition_4e;
      transform-origin: center;
      flex-shrink: 0;

      &:hover {
        transform: scale(1.3);
        @extend %shadow-low;
      }
    }
  }

  .replicar{
    position: absolute;
    right: -$mg_mini;
    bottom: -$hmg_mini;
    transform: translateY(70%);
    width: 340px;
    
    .replicarController {
      padding: $mg_mini $mg_mini $mg_mini $hmg_mid;
      display: flex;
      align-content: center;
      align-items: center;
      gap: $hmg_mid;
      @extend %shadow-low-light;
    }

    nav {
      display: flex;
      align-content: center;
      align-items: center;
      gap: $mg_mini;
    }
  }
}

.frame {
  >footer {
    position: fixed;
    bottom: 0; right: 10px;
    background-color: $color-bg;
    border-top: $border-component;
    padding: $hmg_mid;
    width: calc(100vw - 70px);
    z-index: 1000;

    &.hom {
      bottom: 20px;
      border-left: 1px solid $color-warning;
      border-bottom: 1px solid $color-warning;
      border-bottom-left-radius: 6px;
    }
  }
}

.vagaConfirm {
  padding: $hmg_mid;
  text-align: center;

  h5 {
    text-align: center;
  }
  .icon {
    margin: 0 auto $hmg_mid;
    color: $color-warning;
    font-size: 64px;
  }
  ul {
    padding: $hmg_mid 0; margin: 0 auto;
    display: flex; align-items: center;
    align-content: center; justify-content: space-between;
    width: 100%;

    li {
      padding: 0; margin: 0;
      list-style: none;
      text-align: center;
      display: flex; flex-direction: column;
      align-items: center;

      span {
        display: flex; flex-direction: column;
        align-items: center; justify-content: center;
        width: 45px; height: 45px;
        border-radius: 6px;
        font-size: 22px;
        line-height: 1;
        font-weight: bold; color: $color-secondary;
        margin-bottom: $mg_mini;
      }
      
      p {
        font-size: $font-size-small
      }
      
      &:nth-child(1){
        span {
          background-color: $color-success;
        }
      }
      &:nth-child(2){
        span {
          background-color: $color-success-2;
        }
      }
      &:nth-child(3){
        span {
          background-color: $color-warning;
        }
      }
    }
  }

  footer {
    width: 100%;
    display: flex; align-items: center;
    justify-content: space-between;
  }
}

.done {
  color: $color-success-2;
  font-size: 24px;
}
</style>