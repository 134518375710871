<script setup>
  import ChatbotService from '@/services/chatbot.service.js'
  import VueMarkdown from 'vue-markdown-render'
</script>
<template>
  <controller>
    <page-header title="Conversas" />
    <section class="frame conversas">
      <header :class="['frame nano',{ empty: empty }]">
        <h2>Insira o ID da Negociação</h2>
        <input-control v-model="query" placeholder="67646108f655120017f9620d" :valid="valid" @keyup="valid = true" @onsearch="getConversas" search/>
      </header>
      <div>
        <header v-if="person" class="headerPessoa">
          <div v-if="person.nome">
            <p>Nome</p>
            <h3>{{ person.nome }}</h3>
          </div>
          <div v-if="person.email">
            <p>Email</p>
            <h3>{{ person.email }}</h3>
          </div>
          <div v-if="person.telefone">
            <p>Telefone</p>
            <h3>{{ person.telefone }}</h3>
          </div>
          <div v-if="person.unidade">
            <p>Unidade</p>
            <h3>{{ person.unidade }}</h3>
          </div>
          <div v-if="person.periodoLetivo">
            <p>Telefone</p>
            <h3>{{ person.periodoLetivo }}</h3>
          </div>
        </header>
        <ul v-if="conversas" class="chat">
          <template v-for="conversa in conversas" :key="conversa">
            <li class="user">
              <VueMarkdown :source="conversa.message" class="markdown"/>
            </li>
            <li class="bot">
              <VueMarkdown :source="conversa.response" class="markdown"/>
            </li>
          </template>
        </ul>
      </div>
    </section>
  </controller>
</template>

<script>
export default {
  component: {
    VueMarkdown
  },
  props: {
    id: {
      type: String
    }
  },
  watch: {
    id ( value ) {
      if ( value ) {
        this.query = value
        this.getConversas()
      }
    }
  },
  data () {
    return {
      conversas: null,
      query: null,
      valid: true,
      empty: true,
      person: null
    }
  },
  mounted () {
    if ( this.id ) {
      this.query = this.id
      this.getConversas()
    } 
  },
  methods: {
    getConversas () {
      if ( this.query ) {
        ChatbotService.getConversas( this.query ).then(
          response => {
            console.log('getConversas:', response.data)
            if ( response.data.length > 0 ) {
              this.empty = false
              this.conversas = response.data

              this.getLead()
            }
          },
          error => {
            console.error(error)
          }
        )
      } else {
        this.valid = false
      }
      
    },
    getLead () {
      // 66d0a41ddc5b870001adfae8
      console.log("getLead...")
      ChatbotService.getLead(this.query).then(
        response => {
          console.log("getLead", response.data)

          const rdProfile = response.data.deal_custom_fields.reduce((acc, obj) => {
            const key = obj.custom_field.label
            const value = obj.value
            acc[key] = value
            return acc
          }, {})

          rdProfile.nome = response.data.name

          console.log ("rdProfile", rdProfile)
          this.person = {
            nome: rdProfile.nome,
            email: rdProfile.Email,
            telefone: rdProfile['Telefone'],
            unidade: rdProfile['Unidade'],
            periodoLetivo: rdProfile['Ciclo de Captação']
          }
        },
        error => {
          console.error(error)
        }
      )
    },
  }
}
</script>
<style lang="scss">
  .markdown {
    p+p {
      margin: 0; padding: 0
    }
  }
</style>
<style lang="scss" scoped>
  .conversas {
    >header {
      padding: $mg 0;
      text-align: center;
      @extend %transition_6e;

      h2 {
        margin-bottom: $mg_mini;
      }

      &.empty {
        position: absolute; top: 50%;
        transform: translateY(-50%);
        left: 0; right: 0; margin: auto;
      }
    }

    .headerPessoa {
      display: flex; align-items: center;
      justify-content: center;
      gap: $hmg_mid;
      border-bottom: $border-component;
      padding: $hmg_mid;

      div {
        padding: $mg_mini; border-radius: $border-radius-small;
        background-color: $color-secondary;

        p {
          margin: 0; padding: 0;
        }

        h3 {
          margin: 0; padding: 0;
        }
      }
    }

    .chat {
      padding: $mg;
      margin: 0;
      display: flex;
      flex-direction: column;

      li {
        list-style: none; margin: 0;
        width: 80%;
        border-radius: $border-radius;
        padding: $hmg_mid; margin-bottom: $hmg_mid;

        &.bot {
          border: $border-component;
          align-self: flex-end;
        }
        &.user {
          background-color: $color-secondary;
          font-size: $font-size-larger;
        }
        
      }
    }
  }
</style>