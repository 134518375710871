<template>
  <section>
    <div v-if="turma" class="frame fixed" ref="turmaForm">
      <div class="row no-padd" >
        <div class="col-md-4 form-group">
          <label>Unidade</label>
          <select-control 
            v-model="turma.idUnidade" 
            name="idUnidade" 
            :options="options.unidades" 
            placeholder="Selecione" 
            @change="getPeriodosLetivos(); valid.idUnidade = true" 
            :disabled="loading"
            :valid="valid.idUnidade" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Turno</label>
          <select-control 
            v-model="turma.idTurno" 
            :options="options.turnos" 
            placeholder="Selecione" 
            name="idTurno"
            :disabled="loading"
            :valid="valid.idTurno" 
            @change="valid.idTurno = true"
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Tipo de Curso</label>
          <select-control 
            v-model="tipoCurso" 
            name="tipoCurso" 
            :options="options.tipoCurso" 
            placeholder="Selecione" 
            :disabled="loading" 
            @change="
              clearFilter('tipoCurso'); 
              selectTipoCurso(); 
              valid.tipoCurso = true
            "
            :valid="valid.tipoCurso" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Período Letivo</label>
          <select-control 
            v-model="turma.idPeriodoLetivo" 
            :options="options.periodosLetivos" 
            placeholder="Selecione" 
            name="idPeriodoLetivo"
            @change="
              getSeries(); 
              clearFilter('periodoLetivo');
              valid.idPeriodoLetivo = true
            " 
            :disabled="loading"
            :valid="valid.idPeriodoLetivo" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Curso</label>
          <select-control 
            v-model="turma.idCurso" 
            :options="options.cursos" 
            placeholder="Selecione" 
            name="idCurso"
            :disabled="loading" 
            @change="
              clearFilter('curso'); 
              selectCurso();
              valid.idCurso = true
            "
            :valid="valid.idCurso" 
            required
          />
        </div>
        <div class="col-md-4 form-group">
          <label>Série</label>
          <select-control 
            v-model="turma.idSerie" 
            :options="options.series" 
            placeholder="Selecione" 
            name="idSerie"
            :disabled="loading"
            :valid="valid.idSerie" 
            @change="valid.idSerie = true"
            required
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-4 form-group">
          <label>Descrição</label>
          <input-control
            v-model="turma.descricao" 
            placeholder="" 
            name="descricao"
            type="text"
            :valid="valid.descricao" 
            @keyup="valid.descricao = true"
            required
          />
        </div>
        <div class="col-md-6 form-group">
          <label>Detalhes</label>
          <input-control
            v-model="turma.detalhes" 
            placeholder="" 
            name="descricao"
            type="text"
            :valid="valid.detalhes" 
          />
        </div>
      </div>
      <div class="row no-padd">
        <div class="col-md-2 form-group">
          <label>Data Início</label>
          <input-control
            v-model="turma.dataInicio" 
            placeholder="" 
            name="dataInicio"
            type="date"
          />
        </div>
        <div class="col-md-2 form-group">
          <label>Data Final</label>
          <input-control
            v-model="turma.dataFinal" 
            placeholder="" 
            name="dataFinal"
            type="date"
          />
        </div>
        <div class="col-md-2 form-group">
          <label>Capacidade</label>
          <input-control
            v-model="turma.capacidade" 
            placeholder="" 
            name="capacidade"
            type="number"
            :valid="valid.capacidade" 
            @keyup="valid.capacidade = true"
            required
          />
        </div>
        <!-- <div class="col-md-3 form-group checks">
          <label class="check-control super">
            <input type="checkbox" class="check" v-model="turma.itinerario"/>
            <span>Itinerário</span>
          </label>
        </div> -->
        <!-- <div class="col-md-2 form-group">
          <label>Vagas</label>
          <input-control
            v-model="turma.vagas" 
            placeholder=""
            name="vagas"
            type="number"
            :valid="valid.vagas" 
            @keyup="valid.vagas = true"
            required
          />
        </div> -->
        <!-- <div class="col-md-2 form-group">
          <label>Meta de Ocupação</label>
          <input-control
            v-model="turma.ocupacaoMeta" 
            placeholder=""
            name="ocupacaoMeta"
            type="number"
          />
        </div> -->
      </div>
      <div class="row no-padd">
        <div class="col-md-12 form-actions form-group">
          <a href="#" @click.prevent="save()" class="btn btn-primary">
            Salvar Turma
          </a>
          <a href="#" @click.prevent="cancel()" class="btn btn-secondary">
            Cancelar
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'
import FormService from '@/services/form.service.js'

export default {
  props: {
    scope: {
      type: Object
    }
  },
  data () {
    return {
      turma: null,
      valid: {
        descricao: true,
        idUnidade: true,
        idTurno: true,
        idPeriodoLetivo: true,
        idCurso: true,
        idSerie: true,
        tipoCurso: true
      },
      wait: {
        unidades: false,
        turnos: false,
        cursos: false,
        tipoCurso: false
      },
      options: {
        unidades: [],
        turnos: [],
        tipoCurso: [],
        periodosLetivos: [],
        series: [],
        turmas: [],
        cursos: [],
      },
      tipoCurso: null,
      cursos: null,
      series: null,
      loading: false
    }
  },
  mounted () {
    this.turma = this.scope
    this.getUnidades()
    this.getTipoCurso()
    this.getCursos()
    this.getTurnos()
  },
  methods: {
    getUnidades () {
      UserService.getMinhasUnidades().then(
        response => {
          console.log("getMinhasUnidades:", response.data)
          this.options.series = []
          this.options.periodosLetivos = []
          
          if ( response.data.length > 0 ) {
            this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
            this.wait.unidades = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
        }
      )
    },
    getTurnos () {
      AcademicoService.getTurnos().then(
        response => {
          console.log("getTurnos:", response.data)
          if ( response.data.length > 0 ) {
            this.options.turnos = createSelectOptions(response.data, 'idTurno' ,'descricao')
            this.wait.turnos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getCursos () {
      AcademicoService.getCursos().then(
        response => {
          console.log("getCursos:", response.data)
          if ( response.data.length > 0 ) {
            this.cursos = response.data
            this.options.cursos = createSelectOptions(response.data, 'idCurso' ,'descricao')
            this.wait.cursos = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
          }

         
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getTipoCurso () {
      AcademicoService.getTipoCurso().then(
        response => {
          console.log("getTipoCurso:", response.data)
          if ( response.data.length > 0 ) {
            this.options.tipoCurso = createSelectOptions(response.data, 'idTipoCurso' ,'descricao')
            this.wait.tipoCurso = true
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum tipo de curso encontrado. Verifique sua conexão e tente novamente.", floater: true})
          }
          
          this.finishLoad()
        },
        error => {
          console.error(error)
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum tipo de curso encontrado. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getPeriodosLetivos () {
      this.loading = true
      AcademicoService.getPeriodosLetivos(this.turma.idUnidade).then(
        response => {
          console.log("getPeriodosLetivos:", response.data)
          
          if ( response.data.length > 0 ) {
            this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
          }
          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    getSeries () {
      this.loading = true
      // const tipoCurso = 
      AcademicoService.getSeries(this.turma.idPeriodoLetivo, this.tipoCurso).then(
        response => {
          console.log("getSeries:", response.data)
          this.options.series = []

          if ( response.data.length > 0 ) {
            this.series = response.data
            response.data.map( k => {
              k.label = k.descricao + ' ' + k.descricaoTurno
            })
            this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
            
          } else {
            window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
          }

          this.loading = false
        },
        error => {
          console.error(error)
          this.loading = false
          window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
        }
      )
    },
    selectTipoCurso () {
      const selected = this.tipoCurso
      this.loading = true
      
      const result = this.cursos.filter( k => {
        return k.idTipoCurso == selected
      })
      
      if ( result.length > 0 ) {
        this.options.cursos = createSelectOptions(result, 'idCurso' ,'descricao')
      } else {
        this.options.cursos = []
      }
      this.loading = false
    },
    finishLoad () {
      const finished = !Object.values(this.wait).some( a => !a)

      if ( finished ) {
        window.spinner.emit("close")
        this.loading = false
      }
      // console.log("finished:", finished)

    },
    selectCurso () {
      const selected = this.turma.idCurso
      this.loading = true
      
      const result = this.series.filter( k => {
        return k.idCurso == selected
      })

      if ( result.length > 0 ) {
        this.options.series = createSelectOptions(result, 'idSerie' ,'label')
      } else {
        this.options.series = []
      }
      this.loading = false
    },
    clearFilter ( part ) {
      if ( part === 'tipoCurso' ) {
        this.turma.cursos = null
        this.turma.periodoLetivo = null
        this.turma.series = null
        this.options.series = this.series
      } else if ( part === 'periodoLetivo' ) {
        this.turma.cursos = null
        this.turma.series = null
        this.options.series = this.series
      } else if ( part === 'curso' ) {
        this.turma.series = null
        this.options.series = this.series
      }

    },
    save () {
      const valid = FormService.validate(this.$refs.turmaForm, this.valid)
      console.log("valid:", valid)

      if ( valid.response ) {
        window.spinner.emit('open')
        AcademicoService.saveTurma( this.turma, 1 ).then(
          response => {
            console.log("response", response)
            if ( response.status == 200 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo! Turma criada com sucesso.", floater: true})
            }
            window.spinner.emit('close')
          },
          () => {
            window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Não foi possível salvar a turma informada.", floater: true})
            window.spinner.emit('close')
          }
        )
      }
    },
    cancel () {
      this.$emit('onCancel')
    }
  }
}
</script>

<style lang="scss" scoped>
  // .frame {
  //   padding: 0 $hmg_small
  // }

  .form-actions {
    margin-top: $hmg !important
  }
</style>