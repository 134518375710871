<script setup>
  import MatriculaService from '@/services/matricula.service.js'
</script>
<template>
  <section>
    <div v-if="aluno">
      <h1>{{ aluno.nome }}</h1>
      <h3>{{ aluno.rm }}</h3>
      <h3>{{ aluno.nascimento }}</h3>
      <h3>{{ aluno.serie }}</h3>
      <h3>{{ aluno.curso }}</h3>
    </div>
    
  </section>  
</template>

<script>
export default {
  name: 'Carteirinha Aluno',
  props: {
    idPessoa: {
      type: [String, Number]
    }
  },
  data () {
    return {
      aluno: null
    }
  },
  mounted () {
    this.getAluno()
  },
  methods: {
    getAluno () {
      MatriculaService.getPessoaByIdPessoaOuCpf( this.idPessoa, 0 ).then(
        response => {
          console.log("getPessoaByIdPessoaOuCpf:", response.data)
          if ( Object.keys(response.data).length > 0 ) {
            this.alunoFull = response.data.dados
            
            const base = response.data.dados
            this.aluno = {
              id: base.Aluno.idAluno,
              nome: base.Basicos.nome,
              rm: base.Aluno.rm,
              nascimento: base.Basicos ? base.Basicos.dataNascimento : "-",
              rg: base.Documento ? base.Documento.rg : "-",
              cpf: base.Documento ? base.Documento.cpf : "-",
              email: base.Aluno ? base.Aluno.emailAcademico : "-",
              genero: base.Basicos ? base.Basicos.sexo : "-",
              serie: base.Aluno.nomeExibicao,
              curso: base.Aluno.descricao
            }

            
            if ( base.Aluno.responsavelFinanceiro ) {
              // this.getRespFin(base.Aluno.responsavelFinanceiro.idPessoa)
            }
            
            // if ( base.Basicos.idPessoaPai ) this.fetchParents('1', base.Basicos.idPessoaPai)
            // if ( base.Basicos.idPessoaMae ) this.fetchParents('2', base.Basicos.idPessoaMae)
        }
        },
        () => {

        }
      )
    },
  }
}
</script>

<style>

</style>