import axios from 'axios'
import authHeader from './auth-header'


class ChatbotService {
  getConversas ( idDeal ) {
    return axios.get(process.env.VUE_APP_API_URL + `assistants/get/messages/${ idDeal }`, { headers: authHeader() })
  }
  getLead ( lid ) {
    return axios.get(process.env.VUE_APP_API_URL + 'public/chatbot/getlead/' + lid, { headers: authHeader() })
  }
}

export default new ChatbotService()