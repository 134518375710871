<template>
  <card :boxed="false" :listed="false" :actions="true">
    <div class="statusTag errorTag" v-if="!setStatusClass(matricula)" title="Aparentemente está matricula está ativa no Apollo, mas não existe ou não foi vinculada no Perseus.">
      <p>Erro de integração</p>
      <fa :icon="['fas','triangle-exclamation']" class="icon"></fa>
    </div>
    <div class="statusTag" v-else>
      <p>{{ matricula.nomeSituacao }}</p>
      <span :class="setStatusClass(matricula)"></span>
    </div>
    <template v-if="ca">
      <header>
        <div>
          <h5>
            {{ matricula.nomeCurso }}
          </h5>
          <p>{{ formatDate(matricula.dataSituacao) }}</p>
        </div>
      </header>
    </template>
    <template v-else>
      <header>
        <div class="picWrap" v-if="matricula.urlFoto">
          <span v-if="matricula.urlFoto" :style="{ backgroundImage: `url('${ matricula.urlFoto }')` }"></span>
        </div>
        <div>
          <h4 class="text-capitalize">
            {{ matricula.nomeAluno.toLowerCase() }}
          </h4>
          <p>{{ matricula.nomeCurso }}</p>
        </div>
      </header>
    </template>
    <ul class="cardDetails">
      <li v-if="!ca">
        <label>Data Matrícula</label>
        <p>{{ formatDate(matricula.dataSituacao) }}</p>
      </li>
      <li>
        <label>Unidade</label>
        <p>{{ matricula.nomeUnidade }}</p>
      </li>
      <li>
        <label>Período</label>
        <p>{{ matricula.nomePeridoletivo }}</p>
      </li>
      <li>
        <label>Série</label>
        <p>{{ matricula.nomeSerie }}</p>
      </li>
      <li>
        <label>Turma</label>
        <p>{{ matricula.nomeTurma }}</p>
      </li>
    </ul>
    <ul class="cardDetails" v-if="matricula.nomeFinanceiro || matricula.nomePedagogico">
      <li v-if="matricula.nomeFinanceiro">
        <label>Responsável Financeiro</label>
        <p>{{ matricula.nomeFinanceiro }}</p>
      </li>
      <li v-if="matricula.nomePedagogico">
        <label>Responsável Pedagógico</label>
        <p>{{ matricula.nomePedagogico }}</p>
      </li>
    </ul>
    <ul class="cardDetails" v-if="matricula.planoPagamento && ca">
      <li>
        <label>Plano de Pagamento</label>
        <p>{{ matricula.planoPagamento.descricao }}</p>
      </li>
    </ul>
    <ul class="cardDetails" v-if="matricula.formapagamento && ca">
      <li>
        <label>Forma de Pagamento (Shop)</label>
        <p>{{ matricula.formapagamento.descricao }}</p>
      </li>
    </ul>
    <ul class="cardDetails" v-if="matricula.voucher && ca">
      <li>
        <label>Voucher</label>
        <p>{{ matricula.voucher.codigoVoucher }} ({{ matricula.voucher.porcentagem ? matricula.voucher.porcentagem + '%' : 'R$ ' + matricula.voucher.valor }})</p>
      </li>
    </ul>
    <ul class="cardDetails full" v-if="matricula.itinerariosInscritos.length > 0">
      <li>
        <label>Itinerários Formativos</label>
        <ul class="cardActionList">
          <template v-for="iti in matricula.itinerariosInscritos" :key="iti">
            <li>
              <span>{{ iti.detalhes }}</span>
              <a href="#" @click.prevent="toggleRemoveItinerario(matricula,iti)">
                <fa :icon="['fal','xmark']"></fa>
              </a>
            </li>
            <li class="sep">|</li>
          </template>
        </ul>
      </li>
    </ul>
    <div class="profileMenu" v-if="matricula.perfislPessoa.length > 0 && !ca && perfilPedagogico">
      <div>
        <div>
          <fa :icon="['far', 'id-card']" class="i"/>
          <h5>Perfis</h5>
        </div>
        <nav>
          <a href="#" class="btn btn-bg btn-small" v-for="profile in matricula.perfislPessoa" :key="profile" @click.prevent="toggleActiveProfile(profile)">{{ profile.nome }}</a>
        </nav>
      </div>
      <div v-if="activeProfile" class="activeProfile">
        <h5>Perfil {{ activeProfileName  }}</h5>
        <textarea-control v-model="activeProfile" textonly></textarea-control>
      </div>
    </div>
    <template v-slot:actions>
      <div class="actions-wrap">
        <nav class="actions">
          <template v-if="ca">
            <a href="#" title="Editar Matrícula" @click.prevent="callAction('Editar Matrícula', matricula)" v-if="checkActionVisibility('editar', matricula)">
              <fa :icon="['far','user-pen']" class="icon" />
            </a>
            <a href="#" title="Validar Documentos" @click.prevent="callAction('Validar Documentos', matricula)" v-if="checkActionVisibility('documentos', matricula) && viewDocumento">
              <fa :icon="['far','file-certificate']" class="icon" />
            </a>
            <a href="#" title="Rematrícula" @click.prevent="callAction('Rematrícula', matricula)" v-if="checkActionVisibility('rematricula', matricula)">
              <fa :icon="['far','user-graduate']" class="icon" />
            </a>
            <a href="#" title="Rematrícula" @click.prevent="callAction('Rematrícula', matricula)" v-if="checkActionVisibility('rematricula_bloqueio', matricula)">
              <fa :icon="['far','user-graduate']" class="icon" />
            </a>
            <!-- <a href="#" title="Trocar de Turma" @click.prevent="callAction('Trocar de Turma', matricula)" v-if="checkActionVisibility('turma', matricula)">
              <fa :icon="['far','shuffle']" class="icon" />
            </a> -->
            <a href="#" title="Descontos" @click.prevent="callAction('Descontos', matricula)" v-if="checkActionVisibility('descontos', matricula)">
              <fa :icon="['far','badge-percent']" class="icon" />
            </a>
            <a href="#" title="Itinerários Formativos" @click.prevent="callAction('Itinerários Formativos', matricula)" v-if="checkActionVisibility('itinerarios', matricula)">
              <fa :icon="['far','graduation-cap']" class="icon" />
            </a>
            <router-link v-if="currentUser" :to="{ name: 'Simulador de Preços', params: { idSerie: matricula.idSerie, idFuncao: currentUser.funcao.idFuncao, tipoMatricula: matricula.situacaoRematricula ? 2 : 1 } }">
              <fa :icon="['far','calculator']" />
            </router-link>
          </template>
          <template v-else>
            <a href="#" class="btn btn-primary" @click.prevent="gotoCa(matricula)">
              Central do Aluno
            </a>
            <a href="#" class="btn btn-primary" @click.prevent="gotoMat(matricula)">
              Matrícula
            </a>
          </template>
          <!-- <a href="#" title="Detalhes" @click.prevent="callAction('Detalhes', matricula)" v-if="checkActionVisibility('detalhes', matricula)">
            <fa :icon="['far','magnifying-glass-plus']" class="icon" />
          </a> -->
          
        </nav>
        <div>
          {{matricula.idMatricula}}
          <template v-if="matricula.matCodigo !== null"> / {{matricula.matCodigo}}</template>
        </div>
      </div>
    </template>
  </card>
</template>

<script>
import { formatDate } from '@/services/utils.service'
import UserService from '@/services/user.service.js'

export default {
  props: {
    matricula: {
      type: Object
    },
    ca: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      viewDocumento: false,
      addEditDocumento: false,
      activeProfile: null,
      activeProfileName: null,
      perfilPedagogico: false,
      perfilFinanceiro: false,
      currentUser: localStorage.getItem('profile') ? JSON.parse(atob(localStorage.getItem('profile'))) : null
    }
  },
  mounted () {
    this.viewDocumento = UserService.filterPermissions('viewDocumento')
    this.addEditDocumento = UserService.filterPermissions('addEditDocumento')
    this.perfilPedagogico = UserService.filterPermissions('perfilPedagogico')
    this.perfilFinanceiro = UserService.filterPermissions('perfilFinanceiro')
  },
  methods: {
    setStatusClass ( mat ) {
      const ativo = mat.situacaoAtivo
      const matricula = mat.situacaoMatricula
      const rematricula = mat.situacaoRematricula
      const id = mat.idSituacao
    
      let finalStatus
      const prefix = "status_"

      if ( ativo === 1 ) {
        if ( id === 10 || id === 12 || id === 14 ) {
          finalStatus = "rematricula"
        } else {
          finalStatus = "ativo"
        }
      } else {
        if ( matricula === 1 ) {
          finalStatus = "matricula"
        } else if ( rematricula === 1 ) {
          finalStatus = "rematricula"
        } else {
          finalStatus = "inativo"
        }
      }

      if ( id == 1 && !mat.matCodigo ) {
        return null
      } else {
        return prefix + finalStatus
      }

    },
    formatDate ( date ) {
      return formatDate( date )
    },
    gotoCa ( matricula ) {
      this.$router.push({ name: 'Central do Aluno', params: { idAluno: matricula.idAluno, idPessoa: matricula.idPessoaAluno }})
    },
    gotoMat ( matricula ) {
      this.$router.push({ name: 'Nova Matrícula', params: { action: 'ver', step: 'unidade-curso', idMatricula: matricula.idMatricula }})
    },
    checkActionVisibility ( action, matricula ) {
      const status = parseFloat(matricula.idSituacao)
      const bloqueios = matricula.bloqueios
      const tipoCurso = matricula.siglaTipoCurso
      // console.log("checkActionVisibility:", matricula)

      let finalStatus = true

      switch (action) {
        case "detalhes":
          finalStatus = status === 1 || status === 2 || status === 10 || status === 12 || status === 14
        break
        case "turma":
          finalStatus = status === 1 || status === 10
        break
        case "documentos":
          tipoCurso == 'R'
        break
        case "editar":
        case "loja":
          finalStatus = status === 1
        break
        case "boletos":
          finalStatus = status === 1 || status === 2
        break
        case "pagamentos":
          finalStatus = status === 10
        break
        case "rematricula":
          finalStatus = status === 10 && bloqueios.length > 0 || status === 12 || status === 14
        break
        case "descontos":
          // finalStatus = status === 10 || status === 12 || status === 14 || status === 1
          finalStatus = true
        break
        case "rematricula_bloqueio":
          finalStatus = status === 10 && bloqueios.length == 0
        break
        case "itinerarios":
          finalStatus = status === 1 && tipoCurso == 'R' && matricula.itinerariosDisponiveis.length > 0 && matricula.itinerariosInscritos.length == 0
        break
      }

      return finalStatus
    },
    toggleRemoveItinerario ( matricula, itinerario ) {
      this.$emit('onRemoveItinerarios', { matricula, itinerario } )
    },
    callAction ( target, matricula ) {
      this.$emit('onCallAction', { target, matricula })
    },
    toggleActiveProfile ( profile ) {
      if ( this.activeProfileName == profile.nome ) {
        this.activeProfileName = null
        this.activeProfile = null
      } else {
        this.activeProfileName = profile.nome
        const text = profile.resumoPerfil
        const regex = /\?(.*?)\?/g;
        const clearChars = text.replace(regex, "")
        this.activeProfile = clearChars
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .statusTag {
    display: flex; align-content: center; align-items: center;
    justify-content: flex-end;
    position: absolute; right: $hmg; top: $hmg;

    >span {
      display: block;
      width: 18px; height: 18px;
      border-radius: 100%;
      margin-left: 6px;
      
      &.status_ativo { background-color: $color-success }
      &.status_inativo, &.status_transferido { background-color: $color-light }
      &.status_matricula { background-color: $color-success-2 }
      &.status_rematricula { background-color: $color-alert }
    }

    &.errorTag {
      .icon {
        color: $color-alert;
        margin-left: 6px;
        font-size: 18px;
      }
    }

    p {margin: 0}
  }

  header {
    display: flex;
    align-items: center; align-content: center;
    gap: $mg_small;
    padding: $hmg_mid;

    h4 {
      margin: 0; padding: 0;
    }
    .picWrap {
      padding: 0;
      width: 60px; height: 60px;
      position: relative;

      span {
        display: block;
        width: 60px; height: 60px;
        border-radius: 100%;
        background-size: cover; background-position: center;
        background-repeat: no-repeat;
        background-color: $color-bg;
        image-rendering: -webkit-high-quality;
        image-rendering: -webkit-optimize-contrast;
        @extend %transition_4e;
        transform-origin: center;

        &:hover {
          transform: scale(1.3);
          @extend %shadow-low;
        }
      }
    }

    p {
      margin: 0; padding: 0;
    }
  }

  @media screen and (max-width: 1366px) {
    .statusTag { right: $hmg_mid; top: $hmg_mid; }
  }
  @media screen and (max-width: 1024px) {
    .statusTag { right: $hmg_small; top: $hmg_small; }
  }

  .cardDetails {      
    display: flex;
    align-items: stretch; align-content: center;
    justify-content: space-between;
    list-style: none; margin: 0; padding: 1em 0 0 0; 
    border-top: 1px solid $color-light;
    padding: $hmg_small $hmg_mid;

    >li {
      padding: 0;

      label {
        margin: 0;
        flex: 0;
        font-weight: bold;
        font-size: $font-size-small
      }
      p {
        flex: 1;
        margin: 0; padding: 0;
        // text-align: right;
      }

      &:last-child:not(:first-child) {
        text-align: right;

        label { text-align: right; }
        p { text-align: right; }
      }
    }

    &.full {
      width: 100%;

      >li {
        width: 100%;
      }
    }

    &:last-child {
      li {
        p { margin-bottom: 0; }
      }
    }
  }

  .cardActionList {
    display: flex; flex-wrap: wrap;
    width: 100%; padding: 0; margin: 0;
    align-items: flex-start; align-content: flex-start;
    justify-content: flex-start;

    li {
      display: inline-flex;
      padding: $hmg_mini;
      line-height: 1;
      border-radius: 2em;
      overflow: hidden;
      @extend %transition_3e;
      white-space: nowrap;
      margin-right: $hmg_mini; margin-left: -$hmg_mini;
      position: relative;

      &.sep {

      }

      a {
        opacity: 0;
        width: 20px; height: 20px;
        right: $hmg_mini;
        position: absolute; top: 50%;
        transform: translate(100%,-50%);
        @extend %transition_3e;
        font-size: 14px;

        &:hover{
          color: $color-primary
        }
      }

      &:hover:not(.sep) {
        padding: $hmg_mini $mg_mid $hmg_mini $mg_mini;
        background-color: $color-bg;

        a {
          opacity: 1;
          display: flex; flex-direction: column;
          align-content: center; align-items: center;
          justify-content: center; text-align: center;
          line-height: 1;
          transform: translate(0,-50%);
        }
      }
      &:last-child{ display: none; }
    }
  }

  .profileMenu { 
    border-top: $border-component;
    padding: $hmg_small $hmg_mid;

    >div {
      &:first-child {
        display: flex; align-items: center;
        gap: $hmg_mid;

        div, nav {
          display: flex; align-items: center;
          gap: $mg_mini;
          
          p, h5 {
            margin: 0; padding: 0;
          }
          .i {
            font-size: 16px;
          }
        }
      }
    }
    
  }

  .activeProfile {
    border-top: $border-component;
    margin-top: $hmg_mid; padding-top: $hmg_mid;

    h5 {
      margin-bottom: $mg_mini
    }
  }
</style>