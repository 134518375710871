<template>
  <controller :lockScroll="ls">
    <section class="ca-controller">
      <div :class="{ small: active != 'matriculas' }">
        <info-bar :aluno="alunoFull" v-if="alunoFull" class="infoBar"/>
      </div>
      <div :class="{ large: active != 'matriculas' }">
        <matriculas :aluno="alunoFull" @onModal="toggleModal" @onRespFin="respFin = $event" v-if="alunoFull && active == 'matriculas'" @onCreateFilter="setPeriodoList" @onRemoveItinerarios="confirmRemoveItinerario" ref="matriculas"/>
        <bloqueios :idAluno="alunoFull.Aluno.idAluno" v-if="alunoFull && active == 'bloqueios'" @goBack="active = 'matriculas'" />
        <negociacoes :idAluno="alunoFull.Aluno.idAluno" :aluno="alunoFull" v-if="alunoFull && active == 'negociacoes'" @goBack="active = 'matriculas'" />
      </div>
      <div>
        <toolbar :aluno="alunoFull" v-if="alunoFull" @onAddPanel="addPanel" @onModal="toggleModal" @onNavigation="toggleNavigation" :fin="respFin"/>
      </div>
    </section>
    <template v-if="painel && alunoFull && periodosLetivos && selectedPeriodo">
      <painel :aluno="alunoFull" :painel="painel" @onGoBack="removePanel()" :periodosLetivos="periodosLetivos" :pl="selectedPeriodo"/>
    </template>
    <modal ref="modal">
      <documentos v-if="modalContent == 'documentos'" :id="idMatricula" @onclose="closeModalControl()" :validation="addEditDocumento" :reenvioContrato="reenvioContrato" :matricula="selectedMatricula" />
      <boletos v-if="modalContent == 'boletos'" :matricula="idMatricula" :gestao="true" />
      <div v-if="modalContent == 'itinerarios'">
        <itinerarios :itinerarios="itinerarios" :matriculas="matriculasItinerarios" :matricula="idMatricula" @save="closeModalControl(true)" @close="closeModalControl()" />
      </div>
      <div v-if="modalContent == 'remanejamento'">
        <remanejamento :idMatricula="idMatricula" @onClose="closeModalControl($event)" />
      </div>
    </modal>
    <confirm 
      :backdrop="false"
      class="confirm"
      ref="confirm"
    ></confirm>
  </controller>
</template>

<script>
import InfoBar from '@/components/centralaluno/template/InfoBar.vue'
import Matriculas from '@/components/centralaluno/template/Matriculas.vue'
import Toolbar from '@/components/centralaluno/template/Toolbar.vue'
import Painel from '@/components/centralaluno/template/Painel.vue'
import Modal from "@/components/utilities/Modal.vue"
import Remanejamento from '@/views/matriculas/remanejamento/Remanejamento.vue'
import Boletos from '@/views/boletos/Boletos.vue'
import Documentos from '@/views/centralaluno/Documentos.vue'
import Itinerarios from '@/views/centralaluno/Itinerarios.vue'
import Bloqueios from '@/views/centralaluno/Bloqueios.vue'
import Negociacoes from "@/views/centralaluno/Negociacoes.vue"

import UserService from '@/services/user.service.js'
import MatriculaService from '@/services/matricula.service.js'

export default {
  components: {
    InfoBar, Matriculas, Toolbar, Painel,
    Remanejamento, Modal, Boletos, Documentos,
    Itinerarios, Bloqueios, Negociacoes
  },
  props: {
    idPessoa: {
      type: [ Number, String ]
    },
    idAluno: {
      type: [ Number, String ]
    },
    action: {
      type: [ Number, String ]
    }
  },
  watch: {
    action ( value ) {
      if ( value ) this.painel = value
    }
  },
  data () {
    return {
      alunoFull: null,
      aluno: null,
      painel: null,
      ls: false,
      active: 'matriculas',
      idMatricula: null,
      modalContent: null,
      periodosLetivos: null,
      selectedPeriodo: null,
      viewDocumento: false,
      addEditDocumento: false,
      reenvioContrato: false,
      itinerarios: null,
      matriculasItinerarios: null,
      selectedMatricula: null,
      respFin: null,
    }
  },
  mounted () {
    this.viewDocumento = UserService.filterPermissions('viewDocumento')
    this.addEditDocumento = UserService.filterPermissions('addEditDocumento')
    this.reenvioContrato = UserService.filterPermissions('reenvioContrato')

    this.getAluno()
    window.pagescroll.emit("unlock")

    if ( this.action ) {
      this.addPanel(this.action)
    }
  },
  methods: {
    getAluno () {
      MatriculaService.getPessoaByIdPessoaOuCpf(this.idPessoa,0).then(
        response => {
          console.log("getPessoaByIdPessoaOuCpf:", response.data)
          if ( Object.keys(response.data).length > 0 ) {
            this.alunoFull = response.data.dados
            
            const base = response.data.dados
            const aluno = {
              id: base.Aluno.idAluno,
              nome: base.Basicos.nome,
              rm: base.Aluno.rm,
              nascimento: base.Basicos ? base.Basicos.dataNascimento : "-",
              rg: base.Documento ? base.Documento.rg : "-",
              cpf: base.Documento ? base.Documento.cpf : "-",
              email: base.Aluno ? base.Aluno.emailAcademico : "-",
              genero: base.Basicos ? base.Basicos.sexo : "-",
              idRespFin: base.Aluno.responsavelFinanceiro?.idPessoa ? base.Aluno.responsavelFinanceiro.idPessoa : "-"
            }

            // console.log("getPessoaByIdPessoaOuCpf:", base)
            this.aluno = aluno
            
            if ( base.Aluno.responsavelFinanceiro ) {
              this.getRespFin(base.Aluno.responsavelFinanceiro.idPessoa)
            }
            
            // if ( base.Basicos.idPessoaPai ) this.fetchParents('1', base.Basicos.idPessoaPai)
            // if ( base.Basicos.idPessoaMae ) this.fetchParents('2', base.Basicos.idPessoaMae)
        }
        },
        () => {

        }
      )
    },
    getRespFin ( idPessoa ) {
      console.log("idPessoa", idPessoa)
      if ( idPessoa ) {
        MatriculaService.getPessoaByIdPessoaOuCpf(idPessoa,0).then(
          response => {
            console.log("getRespFin:", response.data)
            if ( Object.keys(response.data).length > 0 ) {
              this.responsavel = response.data
            }
          },
          () => {

          }
        )
      }
      
    },
    addPanel ( painel ) {
      this.painel = painel
      this.ls = true
    },
    removePanel () {
      this.painel = null
      this.ls = false
    },
    toggleModal  ( options ) {
      const modalOptions = {
        title: options.target,
        titleAlign: "left",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: options.size ?? "large", //small, mid, large, full
        backdrop: true,
        backdropColor: "blur",  //se o backdrop for false, não precisa
        backdropClose: true, //se o backdrop for false, não precisa
      }
      
      // console.log("options.matricula", options.matricula)

      this.idMatricula = options.matricula.idMatricula
      this.selectedMatricula = options.matricula
      this.itinerarios = options.matricula.itinerariosDisponiveis
      this.matriculasItinerarios = options.matricula.itinerariosInscritos
      this.modalContent = options.target

      this.$refs.modal.open(modalOptions)
    },
    toggleNavigation ( target ) {
      this.active = target
    },
    closeModalControl ( update ) {
      this.$refs.modal.close()

      if ( update ) {
        // console.log("update matriculas..")
        this.$nextTick(() => {
          this.$refs.matriculas.getMatriculas()
        })
      }
    },
    setPeriodoList ( options ) {
      this.periodosLetivos = options.periodos
      this.selectedPeriodo = options.selected
    },
    confirmRemoveItinerario ( options ) {
      this.$refs.confirm.run({
        message: "Tem certeza que deseja remover o itinerário selecionado?",
        confirmButton: "Sim",
        denyButton: "Não",
        data: [ options.matricula.idMatricula, options.itinerario.idItinerariosInscritos ],
        callback: this.removeItinerario
      })
    },
    removeItinerario (state, data) {
      if ( state ) {
        const matricula = data[0]
        const itinerario = [data[1]]
        window.spinner.emit('open')
        MatriculaService.desmatricularItinerarios(matricula, itinerario).then(
          response => {
            console.log("response:", response)
            if ( response.status == 200 || response.status == 201 ) {
              window.toaster.emit('open', {style: "success", message: "Tudo certo, itinerário removido com sucesso.", floater: true})
              
              this.$nextTick(() => {
                this.$refs.matriculas.getMatriculas()
              })
            }

            window.spinner.emit('close')
          },
          error => {
            console.log("error:", error)
            window.toaster.emit('open', {style: "error", message: "Ops. Algo deu errado. Não conseguimos remover a matricula do aluno no itinerário selecionado.", floater: true})
            window.spinner.emit('close')
          }
        )
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .ca-controller {
    display: flex; align-content: stretch;
    min-height: calc( 100vh - 60px );

    > div {
      flex: 1;
      width: 40%;
      position: relative;
      @extend %transition_6e;

      &:nth-child(1) {
        background-color: $color-secondary;
        border-right: $border-component;
        z-index: 11;

        &.small {
          width: 30%;
          max-width: 30%;
        }
      }
      &:nth-child(2) {
        z-index: 10;
        // background-color: $color-secondary;
        // border-right: $border-component;
        &.large {
          width: 50%;
        }
      }
      &:nth-child(3) {
        max-width: 320px; min-width: 300px;
        border-left: $border-component;
        z-index: 13;

        @media screen and ( max-width: 1366px ) {
          max-width: 280px; min-width: 240px;
        }

        @media screen and ( max-width: 1024px ) {
          max-width: 100%; min-width: 100%;
        }
      }
    }

    @media screen and ( max-width: 1024px ) {
      display: block;

      >div {
        width: 100%;
      }
    }
  }
</style>