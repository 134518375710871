<template>
  <span>
    <transition name="slideup">
      <a 
        v-if="goToTopButton && goToTopButtonActive" 
        href="#" 
        @click="goToTop()"
        class="btn btn-primary btn-icon btn-round goToTopButton"
        >
        <fa :icon="['far', 'arrow-up']" class="icon"></fa>
      </a>
    </transition>
    <perfect-scrollbar 
      class="main-scroller" 
      :options="options" 
      :watchOptions="true"
      @ps-scroll-y="scroll"
      ref="scroller"
    >
      <div>
        <!-- <confirm 
          :backdrop="true"
          class="confirm"
          ref="confirm"
        ></confirm> -->
        <toaster />
        <spinner color="#FFF"/>
        <header-controller :devmode="devmode"></header-controller>
        <slot></slot>
        <footer-controller></footer-controller>
      </div>
    </perfect-scrollbar>
    <modal ref="modal" wrapped>
      <!-- <img :src="getModalImageRoute()" class="modalBanner"/> -->
      <h5 class="text-center">Família Decisão</h5>
      <div class="pop">
        <p class="text-center">Durante o período de férias as entregas e a análise dos pedidos de cancelamento e devolução serão paralisadas até <b class="color-primary">06/01</b>.</p>
        <!-- <h3 class="text-center spaced"><b class="color-primary">Insira o voucher <span class="pop-tag">RES10</span></b></h3> -->
        <p class="text-center">Mas não se preocupe, você poderá efetuar compras e solicitar cancelamento ou devolução dos seus pedidos, normalmente.</p>
        <h4 class="text-center">Boas Festas. Equipe Shop Decisão</h4>
        <br><br>
      </div>
      <div class="text-center">
        <a class="btn btn-primary" href="#" @click.prevent="closeModal()">Ok. Entendi!</a> 
      </div>
    </modal>
  </span>
  
</template>
<script>
import HeaderController from './Header.vue'
import FooterController from './Footer.vue'
import Modal from "@/components/utilities/Modal"

export default {
  components: {
    HeaderController, FooterController, Modal
  },
  props: {
    goToTopButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      options: {
        suppressScrollY: false,
        minScrollbarLength: 80,
      },
      devmode: false,
      hommode: false,
      goToTopButtonActive: false,
      ease: null,
      scrollTop: 0,
      speed: 8,
      rate: 1
    }
  },
  mounted () {
    window.pagescroll.on('lock', () => {
      // console.log("lock store scroll")
      this.options.suppressScrollY = true
    })

    window.pagescroll.on('unlock', () => {
      // console.log("unlock store scroll")
      this.options.suppressScrollY = false
    })

    window.pagescroll.on('goto', to => {
      this.scrollToPos(to)
    })

    // this.addOcta()

    const getLocation = window.location
    console.log("getLocation:", getLocation)

    const host = getLocation.host.split(".")

    if (host.length > 1) {
      const dom = host[1]
 
      if ( dom == 'des' ) {
        this.devmode = true
      } else if ( dom == 'hom' ) {
        this.hommode = true
      }
    }

    // POPUP SHOP -----------------------
    // setTimeout(() => {
    //   if ( getLocation.pathname == '/loja' || getLocation.pathname == '/loja/') {
    //     this.dropAlert()
    //     window.pagescroll.emit('lock')
    //   }
    // },1000)

  },
  methods: {
    // addOcta () {
    //   // console.log("add octa now...")
    //   this.octaScript = document.createElement('script')
    //   this.octaScript.setAttribute('src', '/octa.js')
    //   // console.log("externalScript:", externalScript)
      
    //   document.head.appendChild(this.octaScript)

    //   const octa = document.getElementById('octadesk-octachat-appchat')
    //   if ( octa ) octa.removeAttribute('class', 'hidden')
    // },
    ok (response) {
      if ( !response ) {
        console.log(window.location)
        const query = window.location.search

        window.location.href = "https://apollo.rededecisao.com/loja/" + query
      }
    },
    dropAlert () {
      const options = {
        title: 'Período de Férias',
        titleAlign: "center",
        // message: "Se quiser uma mensagem...", //não obrigatório
        size: "normal", //small, mid, normal, large, full
        backdrop: true,
        backdropColor: "transparent",  //se o backdrop for false, não precisa
        // backdropClose: false, //se o backdrop for false, não precisa
        fullHeight: false
      }
      
      this.$refs.modal.open(options)
    },
    closeModal () {
      this.$refs.modal.close()
      window.pagescroll.emit('unlock')
    },
    scroll ( e ) {
      // console.log("e:", e)

      if ( e.target.scrollTop > 100 ) {
        this.goToTopButtonActive = true
      } else {
        this.goToTopButtonActive = false
      }
    },
    scrollToPos ( to ) {
      const currentPos = this.$refs.scroller.$el.scrollTop
      const dir = currentPos > to ? 'less' : 'more'

      this.scrollTop = currentPos
      
      this.ease = setInterval(() => {
        if ( dir == 'less' ) {
          if ( this.scrollTop <= to ) {
            clearInterval(this.ease)
            this.scrollTop = 0
            this.ease = null
          } else {
            this.scrollTop = this.scrollTop - this.speed
          }
        } 

        if ( dir == 'more' ) {
          if ( this.scrollTop >= to ) {
            clearInterval(this.ease)
            this.scrollTop = to
            this.ease = null
          } else {
            this.scrollTop = this.scrollTop + this.speed
          }
        }
        
        this.$refs.scroller.$el.scrollTop = this.scrollTop

      }, this.rate)
    },
    goToTop () {
      // console.log("goToTop")      
      this.scrollToPos(0)
    },
    getModalImageRoute() {
      const src = process.env.VUE_APP_PRODUCT_URL + 'aproveita.jpg'
      console.log("src:", src)
      return src
    }
  }
}
</script>
<style lang="scss">
  .main-scroller {
    max-height: 100vh;
    background-color: $color-light;
  }

  .goToTopButton {
    position: fixed; bottom: 85px; right: 20px;
    z-index: 5000;
    @extend %transition_4e;
  }

  .modalBanner{
    display: block; width: 100%; height: auto;
    margin: auto;
    border-radius: $border-radius
  }

  .pop {
    p {
      font-size: $font-size-large
    }
  }
  .spaced {
    padding: $mg_mini 0;
  }
  .pop-tag {
    padding: $hmg_mini $mg_mini; border-radius: $border-radius; background-color: $color-primary; color: $color-secondary;
    line-height: 1;
  }
</style>