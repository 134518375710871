<script setup>
  import Filtro from '@/components/matriculas/remanejamento/utilities/filter.vue'
  import Remanejamentos from '@/components/matriculas/remanejamento/template/Remanejamentos.vue'
  import FormSpinner from '@/components/utilities/FormSpinner.vue'
</script>

<template>
  <controller>
    <page-header title="Remanejamento" />
    <section>
      <header :class="['filter', { ini: !filter || processing}]">
        <h2 v-if="!filter">Selecione a turma que deseja remanejar</h2>
        <filtro @filter="dropFilter" @onProcessing="processing = true" />
      </header>
      <form-spinner v-if="processing" />
      <remanejamentos :scope="matriculas" :filter="filter" :options="options" v-if="matriculas && !processing"/>
    </section>
  </controller>
</template>

<script>
  export default {
    components: {
      Filtro, Remanejamentos, FormSpinner
    },
    watch: {
      action ( value ) {
        if ( value ) {
          this[value] = true
        }
      }
    },
    data () {
      return {
        novo: false,
        editar: false,
        matriculas: null,
        options: null,
        filter: null,
        processing: false
      }
    },
    mounted () {
      this.novo = false
      this.editar = false
      this[this.action] = true
    },
    methods: {
      dropFilter(obj) {
        console.log('obj:', obj)
        this.processing = false
        this.filter = obj.filter;
        this.options = obj.options;
        this.matriculas = obj.matriculas;
      },
      toggleNew ( state ) {
        this.editar = false
        this.novo = false

        if ( state ) {
          this.$router.push({ name: 'AgendaEdu Canais', params: { action: 'novo' } }) 
        } else {
          this.$router.push({ name: 'AgendaEdu Canais', params: {} }) 
        }
      },
      updateList () {
        this.toggleNew(false)
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
    .filter {
      border-bottom: $border-component;
      @extend %transition_4e;
      
      h2 {
        text-align: center;
        margin-bottom: $hmg_mid;
      }

      &.ini {
        border-bottom: none !important;
        position: absolute; top: 50%; transform: translateY(-50%);
        left: 0; right: 0; margin: auto;
      }
    }
  </style>