<template>
    <section>
        <div class="frame" ref="formfilter" >
            <div class="row nop nom">
                <div class="col-md-4 form-group">
                  <select-control v-model="filter.unidade" name="unidade" :options="options.unidades" placeholder="Selecione" @change="getPeriodosLetivos()" :disabled="loadingOpts" label="Unidade" required/>
                </div>
                <div class="col-md-2 form-group">
                  <select-control v-model="filter.periodoLetivo" name="periodoLetivo" :options="options.periodosLetivos" placeholder="Selecione" @change="getSeries()" :disabled="loadingOpts" label="Período Letivo" required/>
                </div>
                <div class="col-md-2 form-group">
                  <select-control 
                    v-model="filter.serie" 
                    name="serie" 
                    :options="options.series" 
                    placeholder="Selecione" 
                    @change="getTurmas()" 
                    :disabled="loadingOpts" 
                    label="Série" 
                    required
                />
                </div>
                <div class="col-md-2 form-group">
                  <select-control v-model="filter.turma" name="turma"  :options="options.turmas" placeholder="Selecione" :disabled="loadingOpts" label="Turma" required/>
                </div>
                <div class="form-actions col-md-2">
                    <a href="#" class="btn btn-primary" @click.prevent="runFilter"> Filtrar </a>
                    <a href="#" class="btn btn-secondary"> Limpar </a>
                </div>
              </div>
        </div>
    </section>
</template>

<script>
import UserService from '@/services/user.service.js'
import AcademicoService from '@/services/academico.service.js'
import { createSelectOptions } from '@/services/utils.service.js'


export default {
    data () {
			return {
				filter: {
					unidade: '',
					periodoLetivo: '',
					serie: '',
					turma: '',
				},
				loaded: false,
				loadingOpts: false,
				options: {
					unidades: [],
					periodosLetivos: [],
					serie: [],
					turmas: []
				},
				valid: {
					unidade: true,
					periodoLetivo: true,
					serie: true,
					turma: true
				}
			}
    },
    mounted () {
			this.getUnidades()
    },
    methods: {
			getUnidades () {
				UserService.getMinhasUnidades().then(
					response => {
						console.log("getMinhasUnidades:", response.data)
						this.options.serie = []
						this.options.periodoLetivo = []
						
						if ( response.data.length > 0 ) {
								this.options.unidades = createSelectOptions(response.data, 'idUnidade' ,'descricao')
								this.loaded = true
						} else {
								window.toaster.emit('open', {style: "error", message: "Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
						}
					},
					error => {
						console.error(error)
						window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma unidade encontrada. Verifique sua conexão e tente novamente.", floater: true})
					}
				)
			},
			getPeriodosLetivos () {
        this.loadingOpts = true
        AcademicoService.getPeriodosLetivos(this.filter.unidade).then(
					response => {
						console.log("getPeriodosLetivos:", response.data)
						
						if ( response.data.length > 0 ) {
								this.options.periodosLetivos = createSelectOptions(response.data, 'idPeriodoLetivo' ,'descricao').reverse()
						} else {
								window.toaster.emit('open', {style: "error", message: "Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
						}
						this.loadingOpts = false
					},
					error => {
						console.error(error)
						this.loadingOpts = false
						window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhum período letivo encontrado para esta unidade. Isto parece ser um problema de parametrização.", floater: true})
					}
        )
			},
			getSeries () {
        if ( !this.nova && this.filter.periodoLetivo || this.nova && this.filter.periodoLetivo ) {
					this.loadingOpts = true
					const curso = this.filter.tipoCurso ?? 0

					AcademicoService.getSeries(this.filter.periodoLetivo, curso).then(
						response => {
							// console.log("getSeries:", response.data)
							this.options.series = []

							if ( response.data.length > 0 ) {
							this.series = response.data
							response.data.map( k => {
									k.label = k.descricao + ' ' + k.descricaoTurno
							})
							this.options.series = createSelectOptions(response.data, 'idSerie' ,'label')
							
							} else {
							window.toaster.emit('open', {style: "error", message: "Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
							}

							this.loadingOpts = false
						},
						error => {
							console.error(error)
							this.loadingOpts = false
							window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma série encontrada para este período letivo. Isto parece ser um problema de parametrização.", floater: true})
						}
					)
        }
			},
			getTurmas () {
        this.loadingOpts = true
        AcademicoService.getTurmas(this.filter.serie).then(
					response => {
						// console.log("getTurmas:", response.data)
						if ( response.data.length > 0 ) {
							this.options.turmas = createSelectOptions(response.data, 'idTurma' ,'descricao')
						} else {
							window.toaster.emit('open', {style: "error", message: "Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
						}
						this.loadingOpts = false
					},
					error => {
						console.error(error)
						this.loadingOpts = false
						window.toaster.emit('open', {style: "error", message: "Ops. Algo aconteceu. Nenhuma turma encontrada para esta série. Isto parece ser um problema de parametrização.", floater: true})
					}
        )
			},
			runFilter () {
				const empty = Object.values(this.filter).some(e => !e)

				if ( empty ) {
					window.toaster.emit('open', {style: "warning", message: "Preencha todos os campos para filtrar.", floater: true})
				} else {
					this.$emit('onProcessing')
					
					const options = {
						idUnidade: this.filter.unidade,
						idPeriodoLetivo: this.filter.periodoLetivo,
						idSerie: this.filter.serie,
						idTurma: this.filter.turma,
					}
					AcademicoService.filtrarMatriculas(options).then(
						response => {
							if (response.data && response.data.length > 0) {   
								const drop = {
									options: this.options,
									filter: this.filter,
									matriculas: response.data
								}
								this.$emit("filter", drop)
							} else {
							window.toaster.emit('open', {style: "error", message: "Nenhuma matrícula encontrada"})
							}
							this.loading = false  
						},
						() => {
							this.running = false
							window.toaster.emit('open', {style: "error", message: "Não foi possível salvar este atendimento. Valide os dados e tente novamente. Se o problema persistir, procure nosso time de suporte.", floater: true})
						}
					)
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.frame {
		padding: $hmg_mid;
	}
	.form-actions {
		margin-top: 0;
	}
</style>