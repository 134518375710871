<template>
  <controller :goToTopButton="true">
    <div class="page-title">
      <h3 class="text-capitalize">Turmas</h3>
      <transition name="slideRightToLeft">
        <a href="#" @click.prevent="toggleNew( true )" class="btn btn-primary" v-if="!novo">
          <span>Nova Turma</span>&nbsp;
          <fa :icon="['fas', 'screen-users']"></fa>
        </a>
      </transition>
    </div>
    <div class="page-inner">
      <transition name="slideup-small">
        <turma v-if="novo || editar" :scope="turma" @onCancel="cancelCreateEdit()"></turma>
        <filter-controller @onFilter="turmas = $event" ref="filter" v-else></filter-controller>
      </transition>
      <turmas v-if="turmas && !novo" :scope="turmas" @onSelect="editTurma" @onSave="refreshFilter()"></turmas>
    </div>
  </controller>
</template>

<script>
import Turmas from '@/components/estruturaacademica/turmas/form/Turmas.vue'
import Turma from '@/components/estruturaacademica/turmas/form/Turma.vue'
import FilterController from '@/components/estruturaacademica/turmas/utilities/FilterTurmas.vue'

export default {
  components: {
    Turmas, Turma, FilterController
  },
  props: {
    action: {
      type: String
    }
  },
  data () {
    return {
      novo: false,
      editar: false,
      turmas: null,
      turma: null,
      model: {
        descricao: null,
        idSerie: null,
        capacidade: 0,
        dataInicio: null,
        dataFinal: null,
        inscritos: 0,
        ativo: true,
        confirmados: 0,
        ocupacaoMeta: null,
        idTurno: null,
        vagas: 0,
        detalhes: null,
        descricaoSerie: null,
        descricaoTurno: null,
        idCurso: null,
        idPeriodoLetivo: null,
        idUnidade: null,
        classroom: 0,
        // itinerario: false
      }
    }
  },
  mounted () {
    if ( this.action ) {
      if ( this.action == 'editar' ) {
        this.$router.push({name: 'Estrutura Acadêmica - Turmas'})
      } else if ( this.action == 'nova') {
        this.toggleNew()
      }
    }
  },
  methods: {
    editTurma ( turma ) {
      this.turma = turma
      this.editar = true

      this.$router.push({name: 'Estrutura Acadêmica - Turmas', params: { action: 'editar'} })
    },
    toggleNew ( route ) {
      this.turma = this.model
      this.novo = true

      if ( route ) {
        this.$router.push({name: 'Estrutura Acadêmica - Turmas', params: { action: 'criar'} })
      }
      
    },
    cancelCreateEdit () {
      this.turma = null
      this.novo = false
      this.editar = false

      this.$router.push({name: 'Estrutura Acadêmica - Turmas'})
    },
    refreshFilter () {
      this.$refs.filter.getTurmas()
    }
  }
}
</script>

<style>

</style>